<template>
  <div class="wxBind">
    <div class="wx_bind_cnt">
      <p class="wx_bind_title">绑定信息</p>
      <div class="wx_bind_main">
        <div>
          <img src="../assets/image/weixin.png" alt="" />
          <span>微信绑定</span>
        </div>
        <div v-if="!bindStatus">
          <span>未绑定</span>
          <span class="link_span" @click="goBind()">绑定</span>
        </div>
        <div v-else>
          <span>已绑定</span>
          <span class="link_span" @click="unBind()">解绑</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "wxBind",
  props: {},
  computed: mapState({
    userId: (state) => state.user.userId,
  }),
  data() {
    return {
      bindStatus: true,
    };
  },
  mounted() {
    this.queryUserBindInfo();
  },
  methods: {
    queryUserBindInfo() {
      this.$api.queryUserBindInfo({ userId: this.userId, type: 2 }).then((res) => {
        if (res.data.code == "-1") {
          this.bindStatus = true;
        } else {
          this.bindStatus = false;
        }
      });
    },
    goBind() {
      window.location.href = "https://whzhjy.cn/sso/login?thirdLoginType=wechatLogin";
    },
    unBind() {
      this.$confirm("此操作将解除微信绑定账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.unBindUser({ userId: this.userId, type: 2 }).then((res) => {
            if (res.data.code == "1") {
              this.$message.success("账号解绑成功");
              this.queryUserBindInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.wxBind {
  width: 100%;
  min-width: 1200px;
  height: 1024px;
  background: url("../assets/image/bind_user_bg.png") no-repeat top center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 84px;
}
.wx_bind_cnt {
  width: 900px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(185, 200, 225, 0.43);
  border-radius: 16px;
  overflow: hidden;
}
.wx_bind_title {
  background: linear-gradient(360deg, #f7faff 0%, #e1edff 100%);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #262626;
  line-height: 22px;
  padding: 19px 0;
  text-align: center;
}
.wx_bind_main {
  padding: 28px 60px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    .link_span {
      color: #368fff;
      cursor: pointer;
    }
  }
  div:first-child {
    width: 15%;
    img {
      width: 48px;
      height: 48px;
    }
    span {
      display: inline-block;
      width: 64px;
    }
  }
  div:last-child {
    width: 60%;
  }
}
</style>
